.doc_title {
  display: flex;
  align-items: center;
  a {
    margin: 0;
    padding: 0;
    font-size: 0.85rem;
    font-weight: bold;
  }
  margin-bottom: 0.2rem;
}
.date-subtitle {
  font-size: 0.8rem;
  color: gray;
  margin-left: 0.5rem;
}
.hit_text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
}
.hit_link {
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
article.hit {
  margin-top: 1rem;
}

.container {
  padding-left: 2rem;
  margin-top: 1rem;
}

.ais-Hits-list {
  padding-left: 1rem;
}
.name_sub {
  border-bottom: 1px dashed black;
}
